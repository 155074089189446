import React , {Component} from 'react';
import {checkPermission} from "../../utils/methods";
import {LandingFooterPanelV3, Layout, NavigationBarV2, WellnessPageV2} from "../../components";
import PropTypes from "prop-types";
class Wellness extends Component{
  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <WellnessPageV2  history={history}/>
        <LandingFooterPanelV3 isUser={authToken} />
      </Layout>
    );
  }
}

Wellness.propTypes = {
  history: PropTypes.object
}
export default Wellness;