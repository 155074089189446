/* eslint-disable no-undef */
import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';

import HowItWorks from '../HowItWorks';

import { NavigationBarV2, LandingFooterPanelV2, Layout } from '../../components';

import { checkPermission } from '../../utils/methods';

class WhyAmigo extends Component {
  componentDidMount() {
    const { history } = this.props;
    history.push('/why-woliba');
  }
 
  redirection = () => <HowItWorks history={this.props.history}/>;

  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;
    return(
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <Switch>
          <Route path="/why-woliba" render={this.redirection}/>
        </Switch>
        <LandingFooterPanelV2 isUser={authToken} />
      </Layout>
    );
  }
}

WhyAmigo.propTypes = {
  history: PropTypes.object
}

export default WhyAmigo;